import m from "mithril";

import { Card } from "../shared/card";
import { projectCardState } from "../shared/projects-card-state";

const curatedExploreProjectIds: string[] = [
  "ndPFito44z5m", // Gear Generator
  "kDZyLtmcS4cl", // Tic Tac Toe Set
  // "SZIAfLwmZvef", // Gift Tags
  "4X1Sunk8l4sy", // Spiral Text
  "s1y1vx8ZdbWa", // Picnic Cup Carrier
  "akHzfMte428j", // Stat Tracker
  "cflNv8jHhSvf", // Dice Tower
  "tiqyxSpuXOrg", // Medical ID Charms
  "cpWOdI6jWDlA", // Earring Stand
  "0Pnu05DyzfP3", // Two-Piece Picture Stand
  "yLYMqFvPu8s6", // Tiered Organizer
  "fFLSrDjhAE0H", // Sharpie Rack
  "3A5myYfjHkLp", // Wall-fish
];

export const CuratedExploreSection: m.Component<{}> = {
  view() {
    let mCards = curatedExploreProjectIds.map((projectId) => {
      const project = projectCardState.getProject(projectId);
      if (project === "loading") return null;
      return m(Card, { project, key: project.projectId });
    });

    return m(".card-grid", [mCards]);
  },
};
