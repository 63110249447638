/**
 * This file manages the login status of the user and has methods for opening a
 * login/signup modal.
 *
 * - `accountState` stores data on the current logged in user.
 * - `accountState.loggedInUser` starts as undefined (user is logged out / has
 *   no account).
 * - Call `await accountState.refreshLoggedInUser()` to populate the logged in
 *   user.
 */

import m from "mithril";

import { ForgotPasswordModal, LoginModal, SignupModal } from "./account-modal";
import { analyticsIdentify, analyticsUnidentify } from "./analy-tics";
import { apiRequest, apiRequestSuccess } from "./api-request";
import { Subscription, UserInfo, UserStorageData } from "./api-types";
import { modalState } from "./modal";

class FeatureFlags {
  hasProFeatures = false;
  hasAdminFeatures = false;

  constructor(subscription?: Subscription, isAdmin?: boolean) {
    if (!subscription) return; // Not logged in

    if (subscription.plan !== "Free") {
      // Enable any features for non-Free users.
      this.hasProFeatures = true;
    }
    if (isAdmin) {
      // Enable any features for admins.
      this.hasAdminFeatures = true;
    }
  }
}

class AccountState {
  loggedInUser: undefined | UserInfo;
  storage: undefined | UserStorageData;
  featureFlags = new FeatureFlags();

  async refreshLoggedInUser() {
    const responseData = await apiRequest("loggedInUser", {});
    if (responseData.success) {
      // ==================================================
      // Automatically upgrade admins to Gift
      // ==================================================

      if (responseData.isAdmin) {
        responseData.subscription = { plan: "Gift" };
      }

      // ==================================================
      // Comment these out for testing different plans
      // ==================================================

      // responseData.subscription = {
      //   plan: "Free",
      //   projectsRemaining: 3,
      // };

      // responseData.subscription = {
      //   plan: "K-12 Education",
      // };

      // responseData.subscription = {
      //   plan: "Pro",
      //   status: "active",
      //   currentPeriodEnd: new Date().toJSON(),
      //   willCancelAtPeriodEnd: false,
      //   isTrial: false,
      //   isPastDue: false,
      //   price: 1900,
      //   period: "monthly",
      //   subscriptionId: "testing123",
      // };

      // responseData.subscription = { plan: "Gift" };

      // ==================================================

      this.loggedInUser = responseData;
      this.storage = responseData.userStorage;
      this.featureFlags = new FeatureFlags(
        this.loggedInUser.subscription,
        this.loggedInUser.isAdmin
      );
      analyticsIdentify(
        this.loggedInUser.userId,
        this.loggedInUser.username,
        this.loggedInUser.email,
        this.loggedInUser.displayName ?? undefined
      );
    } else {
      this.loggedInUser = undefined;
      this.featureFlags = new FeatureFlags();
      this.storage = undefined;
      analyticsUnidentify();
    }

    this.listeners.forEach((listener) => listener());
  }

  openAccountModal(
    modal: "login" | "signup" | "forgotPassword",
    onSuccess?: () => void,
    onDismiss?: () => void
  ) {
    let modalView;
    if (modal === "login") {
      modalView = () => m(LoginModal, { onSuccess, onDismiss });
    } else if (modal === "signup") {
      modalView = () => m(SignupModal, { onSuccess, onDismiss });
    } else if (modal === "forgotPassword") {
      modalView = () => m(ForgotPasswordModal, { onSuccess, onDismiss });
    } else {
      throw new Error(`modal is not one of "login", "signup", or "forgotPassword"`);
    }
    modalState.open({
      modalView,
      onDismiss,
    });
  }
  openAccountModalPromise(modal: "login" | "signup" | "forgotPassword") {
    // This is called by penpal methods which don't redraw. If we instrumented
    // penpal methods to always redraw we could remove this line.
    m.redraw();

    return new Promise<boolean>((resolve) => {
      const onSuccess = () => resolve(true);
      const onDismiss = () => resolve(false);
      this.openAccountModal(modal, onSuccess, onDismiss);
    });
  }

  storageGet<P extends keyof UserStorageData>(property: P) {
    if (!this.storage) {
      console.warn("User not logged in");
      return undefined;
    }
    return this.storage[property];
  }
  async storageSet<P extends keyof UserStorageData>(property: P, value: UserStorageData[P]) {
    if (!this.storage) {
      console.warn("User not logged in");
      return;
    }
    this.storage[property] = value;
    await apiRequestSuccess("setUserStorage", { property, value }, { skipRedraw: true });
  }

  listeners: (() => void)[] = [];
  onRefresh(listener: () => void) {
    this.listeners.push(listener);
  }

  downloadsRemaining() {
    const downloadsThisMonth = accountState.storageGet("downloadsThisMonth") ?? 0;
    return 10 - downloadsThisMonth;
  }
}

export const accountState = new AccountState();
