import "./style/index.less";

import m from "mithril";

import { DashboardPage } from "./dashboard";
import { ExploreLatestPage } from "./explore-latest";
import { LandingPage } from "./landing-page/landing-page";
import { LaserCraftFestLandingPage } from "./landing-page/laser-craft-fest-landing-page";
import { ThunderLandingPage } from "./landing-page/thunder-landing-page";
import { Logout } from "./logout";
import { MarkdownPage, markdownPages } from "./markdown-page";
import { NewProject } from "./new-project";
import { NotFoundPage } from "./not-found";
import { PaymentSuccessPage } from "./payment-success";
import { PricingPage } from "./pricing";
import { K12PricingPage } from "./pricing-k12";
import { UserProfilePage } from "./profile";
import { ReferencePage, referencePages } from "./reference";
import { InvalidResetTokenPage, ResetPasswordPage } from "./reset-password";
import { ResetPasswordSentPage } from "./reset-password-sent";
import { ScheduledMaintenancePage } from "./scheduled-maintenance";
import { SettingsPage } from "./settings";
import { accountState } from "./shared/account";
import { analyticsPageLanding, analyticsPageView } from "./shared/analy-tics";
import { blogOrigin } from "./shared/config";
import { navState } from "./shared/site-layout/nav-state";
import { goToDashboard } from "./shared/util";
import { expandSidebarSectionBasedOnUrl } from "./sidebar";
import { StatsPage } from "./stats";
import { StatsForCanceledPage } from "./stats-canceled";
import { StatsActivityPage } from "./stats-leaderboard";
import { StatsForSubscribedPage } from "./stats-subscribed";
import { StatsUserPage } from "./stats-user";
import { Tutorials } from "./tutorials";
import { UniversalSearchPage } from "./universal-search";
import { adminIsLoggedIn } from "./util";

// Things we want to happen whenever there's a route change. "Hook" code via
// https://ratfactor.com/mithril-route-scroll
const setOrig = m.route.set;
m.route.set = (path, data, options) => {
  // Do the actual route setting, then...
  setOrig(path, data, options);
  // Scroll to top of the page.
  window.scrollTo(0, 0);
  // Close the hamburger nav if its open.
  navState.isHamburgerOpen = false;
  // Register a pageview.
  analyticsPageView();
  // Expand sidebar based on url.
  expandSidebarSectionBasedOnUrl();
};

const externalRedirects: { [route: string]: string } = {
  "/discord": "https://discord.gg/QRsB3VT",
  "/office-hours": "https://calendly.com/cuttlexyz/cuttle-intro",
  "/affiliate":
    "https://cuttlexyz.notion.site/Join-Cuttle-s-Affiliate-Program-954a1459a3924b209f1d1d0135df64c1",
  "/affiliate/apply": "https://forms.gle/9EPouqjuoGyHNUCw9",
  "/affiliate/terms":
    "https://cuttlexyz.notion.site/Affiliate-Program-Terms-of-Service-ab21f8cbae1d4cac95d9e0e277793fe8",
};

const internalRedirects: { [route: string]: string } = {
  "/tutorials": "/learn/video-tutorials", // this was the old link to video tutorials
  "/learn": "/learn/video-tutorials",
  "/learn/basics": "/learn/basics/getting-started",
  "/learn/parameters": "/learn/parameters/getting-started-with-parameters",
  "/learn/scripting": "/learn/scripting/getting-started-with-scripting",
  "/learn/reference": "/learn/reference/math",
};

const initMithril = () => {
  expandSidebarSectionBasedOnUrl();

  m.route.prefix = "";
  m.route(document.body, "/", {
    "/": {
      onmatch() {
        if (accountState.loggedInUser) {
          m.route.set("/dashboard", {}, { replace: true });
          return undefined;
        }
        return LandingPage;
      },
    },

    // These are to support links that might still be floating around.
    "/login": {
      onmatch() {
        m.route.set("/", {}, { replace: true });
        accountState.openAccountModal("login", goToDashboard);
      },
    },
    "/create-account": {
      onmatch() {
        m.route.set("/", {}, { replace: true });
        accountState.openAccountModal("signup", goToDashboard);
      },
    },
    "/signup": {
      onmatch() {
        m.route.set("/", {}, { replace: true });
        accountState.openAccountModal("signup", goToDashboard);
      },
    },
    "/forgot-password": {
      onmatch() {
        m.route.set("/", {}, { replace: true });
        accountState.openAccountModal("forgotPassword", goToDashboard);
      },
    },

    // Dashboard Pages
    "/dashboard": {
      render() {
        return m(DashboardPage, { selectedPage: { page: "home" } });
      },
    },
    "/dashboard/folder/:folderId": {
      render({ attrs: { folderId } }) {
        return m(DashboardPage, { selectedPage: { page: "folder", folderId } });
      },
    },

    "/explore": {
      render() {
        return m(ExploreLatestPage, { showAll: false });
      },
    },
    "/explore/all": {
      render() {
        return m(ExploreLatestPage, { showAll: true });
      },
    },
    "/templates": {
      render() {
        return m(DashboardPage, { selectedPage: { page: "template", tagSlug: "" } });
      },
    },
    "/templates/:tagSlug": {
      render({ attrs: { tagSlug } }) {
        return m(DashboardPage, { selectedPage: { page: "template", tagSlug } });
      },
    },
    "/@cuttle": {
      render() {
        m.route.set("/templates", {}, { replace: true });
        return null;
      },
    },
    "/@:username": {
      render({ attrs: { username } }) {
        return m(UserProfilePage, { username });
      },
    },
    "/new-project": {
      render() {
        return m(NewProject);
      },
    },
    "/logout": {
      render() {
        return m(Logout);
      },
    },
    "/reset-password-sent": {
      render() {
        return m(ResetPasswordSentPage);
      },
    },
    "/invalid-reset-token": {
      render() {
        return m(InvalidResetTokenPage);
      },
    },
    "/reset-password": {
      render() {
        return m(ResetPasswordPage);
      },
    },
    "/settings": {
      render() {
        return m(SettingsPage);
      },
    },
    "/search/:query": {
      render({ attrs: { query } }) {
        return m(UniversalSearchPage, { query });
      },
    },
    "/stats": {
      render() {
        if (!adminIsLoggedIn()) {
          return m(NotFoundPage);
        }
        return m(StatsPage);
      },
    },
    "/stats/user": {
      render() {
        if (!adminIsLoggedIn()) {
          return m(NotFoundPage);
        }
        return m(StatsUserPage);
      },
    },
    "/stats/leaderboard": {
      render() {
        if (!adminIsLoggedIn()) {
          return m(NotFoundPage);
        }
        return m(StatsActivityPage);
      },
    },
    "/stats/subscriptions": {
      render() {
        if (!adminIsLoggedIn()) {
          return m(NotFoundPage);
        }
        return m(StatsForSubscribedPage);
      },
    },
    "/stats/cancellations": {
      render() {
        if (!adminIsLoggedIn()) {
          return m(NotFoundPage);
        }
        return m(StatsForCanceledPage);
      },
    },
    "/learn/video-tutorials": {
      render() {
        return m(Tutorials);
      },
    },
    "/scheduled-maintenance": {
      render() {
        return m(ScheduledMaintenancePage);
      },
    },

    "/learn/reference/:url...": {
      render({ attrs: { url } }) {
        const page = referencePages["/" + url];
        if (page) {
          return m(ReferencePage, { page });
        }
        return m(NotFoundPage);
      },
    },

    "/pricing": {
      render() {
        return m(PricingPage);
      },
    },
    "/pricing/k-12": {
      render() {
        return m(K12PricingPage);
      },
    },
    "/payment-success": {
      render() {
        return m(PaymentSuccessPage);
      },
    },

    "/laserfest": {
      render() {
        return m(LaserCraftFestLandingPage);
      },
    },

    "/thunder": {
      render() {
        return m(ThunderLandingPage);
      },
    },

    // We no longer use these links (it's all in /blog) but there are still
    // links floating around, so redirect.
    "/whats-new": {
      render() {
        window.location.assign(blogOrigin);
        return null;
      },
    },
    "/whats-new/:date": {
      render() {
        window.location.assign(blogOrigin);
        return null;
      },
    },

    // Keep this one at the bottom
    "/:url...": {
      render({ attrs: { url } }) {
        const path = "/" + url;

        // External redirect
        const externalRedirect = externalRedirects[path];
        if (externalRedirect) {
          window.location.assign(externalRedirect);
          return null;
        }

        // Internal redirect
        const internalRedirect = internalRedirects[path];
        if (internalRedirect) {
          m.route.set(internalRedirect, {}, { replace: true });
          return null;
        }

        // Markdown page
        const page = markdownPages[path];
        if (page) {
          return m(MarkdownPage, { page });
        }

        // Page not found
        return m(NotFoundPage);
      },
    },
  });
};

const init = async () => {
  // When the user re-focuses on the browser tab, refresh everything.
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      accountState.refreshLoggedInUser();
    }
  });

  await accountState.refreshLoggedInUser();
  analyticsPageLanding();
  initMithril();
};

init();
