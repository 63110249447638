import m from "mithril";
import { modalState } from "./shared/modal";
import { ConfettiCanvas } from "./confetti";
import { apiRequest } from "./shared/api-request";
import { dashboardState } from "./dashboard-state";
import { accountState } from "./shared/account";

export const PaymentSuccessPage: m.Component<{}> = {
  async oninit() {
    m.route.set("/dashboard", {}, { replace: true });
    keepRefreshingLoggedInUserUntilYourePro();
    modalState.open({
      modalView() {
        return [
          m(ConfettiCanvas),
          m(".modal-box", { style: { "text-align": "center" } }, [
            m("h1", "Congrats!"),
            m("p", "You're now a Cuttle Pro! 🎉"),
            m("button", { onclick: () => modalState.close() }, "Woohoo!"),
          ]),
        ];
      },
    });
    await dashboardState.refresh();
    const projectCount = dashboardState.projects.length;
    const plan = accountState.loggedInUser!.subscription.plan;

    const referral = (window as any).Rewardful?.referral;
    if (typeof referral === "string") {
      apiRequest("logEvent", {
        eventName: "rewardful conversion",
        metadata: {
          referral,
          affiliate: (window as any).Rewardful.affiliate?.id,
        },
      });
    }

    apiRequest("logEvent", {
      eventName: "subscribe",
      metadata: { plan: plan, projectCount: projectCount },
    });
  },
  view() {
    return null;
  },
};

/**
 * Sometimes Stripe takes a bit to send the news that you're Pro. (It's a whole
 * webhook thing.) This function will keep refreshing loggedInUser every second
 * until it sees that you're Pro.
 */
const keepRefreshingLoggedInUserUntilYourePro = async () => {
  if (accountState.loggedInUser?.subscription.plan === "Pro") {
    return;
  }
  await new Promise((resolve) => setTimeout(resolve, 1000));
  await accountState.refreshLoggedInUser();
  keepRefreshingLoggedInUserUntilYourePro();
};
