import m from "mithril";

import { canonicalOrigin } from "../shared/config";
import { SiteLayout } from "../shared/site-layout/site-layout";
import { TagStrip } from "../tag-strip";
import { updateHead } from "../util";
import { ClaimFreeMonthButton } from "./claim-free-month-button";
import { CuratedExploreSection } from "./curated-explore";
import { LandingPageHero } from "./landing-hero";
import { LandingTemplates } from "./landing-templates";
import {
  TestimonialsCommunity,
  TestimonialsEditor,
  TestimonialsTemplates,
} from "./landing-testimonials";

export const ThunderLandingPage: m.Component = {
  view() {
    updateHead({
      title: "Cuttle: Generate Personalized SVG Cut Files in seconds",
      description:
        "Cuttle is a web-based design tool for laser cutting. Make personalized ornaments, cake toppers, keychains, boxes, jewelry, connected text, and more.",
      image: canonicalOrigin + "/images/social.jpg",
    });

    (window as any).rewardful?.("source", "thunder");

    const cta = [
      m(ClaimFreeMonthButton),
      m("h3.hero2-tagline", "Your Thunder purchase includes a 30 day trial of Cuttle Pro"),
    ];

    const mTemplatesBrowse = m(
      m.route.Link,
      { href: "/templates", class: "browse-link" },
      "Browse all templates →"
    );

    const mFeatureTagline = m(".section-tagline", [
      m("h3", "Editor"),
      m("h1", "Use a vector editor ", m("span", "built for cut design")),
    ]);

    const mFeatureSection = m(".feature-section", [
      m(".feature-tiles", [
        m(".feature-tile", [
          m("video", {
            src: "/images/1 - live modifiers.mp4",
            autoplay: "true",
            muted: "true",
            playsinline: "true",
            loop: "true",
            "aria-hidden": "true",
          }),
          m("h2", "Live Modifiers"),
          m(
            "p",
            "Modifiers transform your shapes — repetition, outlines, boolean operations, and more. Modifiers are non-destructive. You can always go back, edit your original shape, and see the modifiers update in real time."
          ),
        ]),
        m(".feature-tile", [
          m("video", {
            src: "/images/2 - reusable components.mp4",
            autoplay: "true",
            muted: "true",
            playsinline: "true",
            loop: "true",
            "aria-hidden": "true",
          }),
          m("h2", "Reusable Components"),
          m(
            "p",
            "Every shape you make is a component you can reuse elsewhere. When you edit a component it updates live everywhere across your project."
          ),
        ]),
        m(".feature-tile", [
          m("video", {
            src: "/images/3 - parametric design.mp4",
            autoplay: "true",
            muted: "true",
            playsinline: "true",
            loop: "true",
            "aria-hidden": "true",
          }),
          m("h2", "Parametric Design"),
          m(
            "p",
            "Rapidly try out variations and iterate faster. Parameters are numbers you can use throughout your project — for material thickness, dimensions, or anything you want to control. As you change parameters, your design updates live."
          ),
        ]),
        m(".feature-tile", [
          m("video", {
            src: "/images/4 - scripting.mp4",
            autoplay: "true",
            muted: "true",
            playsinline: "true",
            loop: "true",
            "aria-hidden": "true",
          }),
          m("h2", "Scripting"),
          m(
            "p",
            "Write your own custom modifiers in JavaScript. You can also edit the code of the built-in modifiers, or use modifiers shared by the community."
          ),
        ]),
      ]),
    ]);

    const mCommunityTagline = m(".section-tagline", [
      m("h3", "Community"),
      m("h1", "Join an amazing community of ", m("span", "artists, engineers, and makers")),
    ]);

    const mCommunityBrowse = m(
      m.route.Link,
      { href: "/explore", class: "browse-link" },
      "Browse all projects from the community →"
    );

    return m(SiteLayout, { className: "landing-page" }, [
      m(".landing-page-content", [
        m(LandingPageHero, { cta }),
        m(TagStrip),
        m(LandingTemplates),
        mTemplatesBrowse,
        m(TestimonialsTemplates),
        mFeatureTagline,
        mFeatureSection,
        m(ClaimFreeMonthButton),
        m(TestimonialsEditor),
        mCommunityTagline,
        m(CuratedExploreSection),
        mCommunityBrowse,
        m(TestimonialsCommunity),
        m(ClaimFreeMonthButton),
      ]),
    ]);
  },
};
