import m from "mithril";

import dayjs from "dayjs";
import { LandingTemplates } from "./landing-templates";
import {
  Testimonial,
  TestimonialsCommunity,
  TestimonialsEditor,
  TestimonialsTemplates,
} from "./landing-testimonials";
import { accountState } from "../shared/account";
import { canonicalOrigin } from "../shared/config";
import { Icon20 } from "../shared/icon";
import { logEvent } from "../shared/log-event";
import { ModalX, modalState } from "../shared/modal";
import { SiteLayout } from "../shared/site-layout/site-layout";
import { CuttlePro, PricingFeature, UpgradeInfo } from "../shared/upgrade";
import { classNames, goToDashboard } from "../shared/util";
import { markdownToHtml, updateHead } from "../util";
import { LandingPageHero } from "./landing-hero";
import { ClaimFreeMonthButton } from "./claim-free-month-button";

export const LaserCraftFestLandingPage: m.Component = {
  view() {
    updateHead({
      title: "Cuttle: Generate Personalized SVG Cut Files in seconds",
      description:
        "Cuttle is a web-based design tool for laser cutting. Make personalized ornaments, cake toppers, keychains, boxes, jewelry, connected text, and more.",
      image: canonicalOrigin + "/images/social.jpg",
    });

    const cta = m(ClaimFreeMonthButtonIfExtended);

    return m(SiteLayout, { className: "laser-craft-fest-landing-page" }, [
      m(".landing-page-content", [
        //
        m(LandingPageHero, { cta }),

        m(".section-tagline", [m("h3", "Templates")]),
        m(LandingTemplates),
        m(ClaimFreeMonthButtonIfExtended),

        m(".section-tagline", [
          m("h3", "Community"),
          m("h1", "Join an amazing community of ", m("span", "artists, engineers, and makers")),
        ]),
        m(TestimonialsTemplates),
        m(TestimonialsEditor),
        m(TestimonialsCommunity),
        m(ClaimFreeMonthButtonIfExtended),

        m(OurWorkshopsAtLCF2024),
        m(OurWorkshopsAtLCF2023),
      ]),
    ]);
  },
};

const workshopBoxes = `
## Making Boxes of All Shapes and Sizes

Federico teaches you everything you need to know to make laser cut boxes with finger joints!

Get started with the free [Open Box template](https://cuttle.xyz/@cuttle/Open-Box-with-Finger-Joints-D2ugGEvYUNfd).

Supplies shown in the workshop (contains affiliate links)

- [Inexpensive digital calipers](https://amzn.to/3wGxcCG) for measuring your material thickness
- [Glowforge Medium Draftboard](https://shop.glowforge.com/products/draftboard), or any plywood or MDF
- [Wood glue](https://amzn.to/3reMrDp) (optional)
- [Wood glue dispenser](https://amzn.to/415K1mC) (optional)

Federico also shows other box templates like the [Hinged Lid Box](https://cuttle.xyz/@cuttle/Hinged-Lid-Box-rTDUUz652dVO) and [Heart Shaped Box](https://cuttle.xyz/@cuttle/Heart-Shaped-Box-7yx9inQPEQHY) at the end of the video.
`;
const workshopQuickly = `
## Keychains, Connected Text, & Cake Toppers — Creating Custom Cut Files Quickly!

Federico shows you some projects you can personalize in seconds with your own custom text!

Featured in this workshop:

- [Keychain Generator](https://cuttle.xyz/@cuttle/Keychain-Generator-j0lMJaBnDHn0)
- [Connected Text](https://cuttle.xyz/@cuttle/Connected-Text-29M9IXUSr5yr)
- [Cake Topper Generator](https://cuttle.xyz/@cuttle/Cake-Topper-Generator-Lq1sMetUmSul)
- [Name Puzzle](https://cuttle.xyz/@cuttle/Name-Puzzle-cuhgyU5lJ7Mo)
- [Name Snowflake Ornament](https://cuttle.xyz/@cuttle/Name-Snowflake-Ornament-5HKNEddwMGMR)

Here are the supplies Federico used in the video:

- [Proofgrade Light Maple Plywood](https://shop.glowforge.com/collections/plywood/products/maple-plywood-finished?variant=39451629191266)
- [Two Tone Blue/White Acrylic](https://houstonacrylic.com/products/two-tone-acrylic-blue-white-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full). Engrave with 1000 Speed, 80 Power, 225 LPI.
- [Gunmetal Gray Mirror Acrylic](https://houstonacrylic.com/products/1-8-black-gunmetal-mirror-acrylic-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full).
- [Purple Starry Sky Acrylic](https://houstonacrylic.com/products/purple-stary-sky-acrylic) - Cut with Proofgrade Medium Black Acrylic (150/full).
- [Nebula Confetti Acrylic](https://houstonacrylic.com/products/1-8-tbd-royal-chunky-hex-confetti-cast-acrylic-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full).
`;
const workshopCuteAnimals = `
## Intro to the Cuttle Editor: Designing Cute Animals

Have you ever wanted to design your own SVG cut files?

Federico shows how to make a cute animal from scratch in the Cuttle editor, inspired by Ed Emberley’s classic Drawing Book of Animals.

This workshop is a bonus for those who purchased the All Access Crafters Pass.

At the end of the class you will have designed your own cute animal keychain and learned the fundamental skills to embark on your design journey!
`;

const workshopChristmasOrnaments = `
## Personalized Christmas Ornaments in Seconds

Type in text and choose options to instantly generate customized SVGs.

We show how to make stocking tags, paw ornaments, and family tree ornaments with the names of everyone in the family.

We also share marketing, pricing, and packaging tips from makers who sold hundreds of these ornaments last Christmas.

Featured in this workshop:

- [Stocking Tags](https://cuttle.xyz/@cuttle/Stocking-Tags-7pAoHPuvL0YT)
- [Paw Ornament](https://cuttle.xyz/@cuttle/Paw-Ornament-irWGANS1uLkF)
- [Family Names Tree Ornament](https://cuttle.xyz/@cuttle/Family-Names-Christmas-Tree-Ornament-3TGvZj8E49p7)
`;

const workshopInlayEarrings = `
## Make Laser Cut Inlay Earrings

We explain what is needed to get the right press-fit with inlays and show you how to use our inlay kerf tester. After that we show you how to use one of our pre-made earring templates including cutting and assembly.

Featured in this workshop:

- [Inlay Kerf Tester](https://cuttle.xyz/@cuttle/Press-Fit-Inlay-Kerf-Tester-fzKFDlQL8TJ1)
- [Rounded Teardrop Earrings](https://cuttle.xyz/@cuttle/Rounded-Teardrop-Inlay-Earrings-E8aplkd4gPhA)
- [Cat Face Earrings](https://cuttle.xyz/@cuttle/Cat-Face-Inlay-Earrings-BhwlGSh34Fr4)
- [Curved Wedge Earrings](https://cuttle.xyz/@cuttle/Curved-Wedge-Inlay-Earrings-gFXPZTXpMb6n)

Here are the supplies Federico used in the video:

- [Glowforge Medium Maple Plywood](https://shop.glowforge.com/products/maple-plywood-finished?variant=20442193559650)
- [Purple Starry Sky Acrylic](https://houstonacrylic.com/products/purple-starry-sky-acrylic?variant=44671863193826)
- [Earhooks and jumprings (aka "findings")](https://www.etsy.com/shop/MonarchPine) - use code CUTTLE10 for 10% off
`;

const workshopDesignInlayEarrings = `
## How to Design Laser Cut Inlay Earrings

We walk you through the process of designing your own inlay earrings using the Cuttle Editor with the helper features contained in the Inlay Kit.

Featured in this workshop:

- [Inlay Kit](https://cuttle.xyz/@cuttle/Inlay-Kit-xIr0u7pAVaMq)

Here are the supplies Federico used in the video:

- [Glowforge Medium Maple Plywood](https://shop.glowforge.com/products/maple-plywood-finished?variant=20442193559650)
- [Glowforge Medium Walnut Plywood](https://shop.glowforge.com/products/walnut-plywood-finished?variant=20442194739298)
- [Earhooks and jumprings (aka "findings")](https://www.etsy.com/shop/MonarchPine) - use code CUTTLE10 for 10% off
`;

const OurWorkshopsAtLCF2024: m.Component<{}> = {
  view() {
    return [
      m(".section-tagline", [m("h1", "Our Workshops at ", m("span", "Laser Craft Fest 2024"))]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-christmas-ornaments.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopChristmasOrnaments))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-inlay-earrings.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopInlayEarrings))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-design-inlay-earrings.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopDesignInlayEarrings))]),
      ]),
    ];
  },
};

const OurWorkshopsAtLCF2023: m.Component<{}> = {
  view() {
    return [
      m(".section-tagline", [
        // m("h3", "Templates"),
        m("h1", "Our Workshops at ", m("span", "Laser Craft Fest 2023")),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [m("img", { src: "/images/laser-craft-fest/lcf-boxes.jpeg" })]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopBoxes))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [m("img", { src: "/images/laser-craft-fest/lcf-quickly.jpeg" })]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopQuickly))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-cute-animals.jpeg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopCuteAnimals))]),
      ]),
    ];
  },
};

const ClaimFreeMonthButtonIfExtended: m.Component<{ className?: string }> = {
  view({ attrs: { className } }) {
    // A secret way to turn on the trial for testing or for occasional
    // stragglers that we want to extend the trial to.
    let showTrialButton = new URLSearchParams(window.location.search).get("extend") === "1";

    // Uncomment this line to turn on Free Trial (that is, when the LCF offer is active).
    // showTrialButton = true;

    if (!showTrialButton) {
      // If we're not showing the trial, then instead we'll show a normal Get
      // Started button.
      return m(GetStartedButton, { className });
    }

    return m(ClaimFreeMonthButton);
  },
};

const GetStartedButton: m.Component<{ className?: string }> = {
  view({ attrs: { className } }) {
    return m(
      "button.create-free-account-button",
      {
        className,
        onclick: async () => {
          if (!accountState.loggedInUser) {
            const success = await accountState.openAccountModalPromise("signup");
            if (!success) return;
          }
          goToDashboard();
        },
      },
      "Get Started →"
    );
  },
};
