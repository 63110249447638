import dayjs from "dayjs";
import m from "mithril";

import { accountState } from "../shared/account";
import { Icon20 } from "../shared/icon";
import { logEvent } from "../shared/log-event";
import { modalState, ModalX } from "../shared/modal";
import { CuttlePro, PricingFeature, UpgradeInfo } from "../shared/upgrade";
import { Testimonial } from "./landing-testimonials";
import { rewardfulReferral } from "../shared/analy-tics";
import { classNames } from "../shared/util";

interface ClaimFreeMonthAttrs {
  className?: string;
}

export const ClaimFreeMonthButton: m.Component<ClaimFreeMonthAttrs> = {
  view({ attrs }) {
    const { className } = attrs;
    return m(
      "button.create-free-account-button",
      {
        className,
        onclick: async () => {
          logEvent("trial promo: clicked claim your free month");

          if (!accountState.loggedInUser) {
            const success = await accountState.openAccountModalPromise("signup");
            if (!success) return;
          }
          logEvent("trial promo: logged in");

          if (accountState.loggedInUser?.subscription?.plan === "Free") {
            modalState.open({ modalView: () => m(ClaimYourFreeMonthModalPage1, attrs) });
          } else {
            modalState.open({ modalView: () => m(AlreadyProModal) });
          }
        },
      },
      "Claim Your Free Month"
    );
  },
};

const AlreadyProModal: m.Component = {
  view() {
    return m(".modal-box.already-pro-modal", [
      m(ModalX),
      m(".already-pro-modal-content", [
        m("h1", "Looks like you already have Cuttle Pro!"),
        m(
          "button",
          { onclick: () => window.location.assign("/dashboard") },
          "Continue to My Projects"
        ),
      ]),
    ]);
  },
};

const ClaimYourFreeMonthModalPage1: m.Component<ClaimFreeMonthAttrs> = {
  view(attrs) {
    const onClickStartTrial = () => {
      modalState.open({ modalView: () => m(ClaimYourFreeMonthModalPage2, attrs) });
    };
    return m(".modal-box.claim-trial-modal", [
      m(ModalX),
      m(".claim-trial-modal-left", [
        m("h1", "Try ", m(CuttlePro), " for free"),

        m("p", `Try all of our premium templates and features, free for 30 days!`),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(`Make personalized <b>Ornaments</b>, <b>Cake Toppers</b>, and more in seconds`)
          ),
        ]),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(
              `Customize your creations with <b>thousands of fonts</b> or upload your own fonts and images`
            )
          ),
        ]),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(`<b>Unlimited downloads</b> for personal or commercial use`)
          ),
        ]),

        m("p", `Take advantage of this limited-time offer and start making more now!`),

        m("button.start-trial-button", { onclick: onClickStartTrial }, "Start my free trial"),
      ]),
      m(".claim-trial-modal-right"),
    ]);
  },
};

const ClaimYourFreeMonthModalPage2: m.ClosureComponent<ClaimFreeMonthAttrs> = () => {
  const info: UpgradeInfo = { paymentPeriod: "yearly" };
  return {
    view() {
      const dueDate = dayjs().add(30, "day");
      const reminderDate = dueDate.subtract(7, "day");
      return m(".modal-box.claim-trial-modal", [
        m(ModalX),
        m(".claim-trial-modal-left", [
          m("h1", "Try ", m(CuttlePro), " for free"),
          m(PricingFeature, "Free 30 day trial, cancel any time"),
          m(PricingFeature, "We'll remind you before your trial ends"),
          m(TrialUpgradePlanSelector, { info }),
          m(".claim-trial-modal-due", [
            m("span.due.later", ["Due ", dueDate.format("MMMM D, YYYY")]),
            m("span.amount.later", info.paymentPeriod === "yearly" ? "US$180" : "US$19"),
            m("span.due", "Due today (30 days free)"),
            m("span.amount", "US$0"),
          ]),
          m(
            "button.start-trial-button",
            {
              onclick: async () => {
                logEvent("trial promo: sent to stripe", { paymentPeriod: info.paymentPeriod });

                const referral = rewardfulReferral();
                const referralParam = referral ? `&referral=${referral}` : "";
                window.location.assign(
                  `https://api.cuttle.xyz/checkout/pro/${info.paymentPeriod}?trial=true${referralParam}`
                );
              },
            },
            "Continue"
          ),
        ]),
        m(".claim-trial-modal-right.page2", [
          m(".subscription-timeline", [
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "🚀"),
              m("h3", "Today"),
              m("span.right", "Start your free 30 day Cuttle Pro trial. Cancel anytime."),
            ]),
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "🔔"),
              m("h3", reminderDate.format("MMMM D")),
              m(
                "span.right",
                "We'll send you a reminder 7 days before your Cuttle Pro trial ends."
              ),
            ]),
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "👑"),
              m("h3", dueDate.format("MMMM D")),
              m(
                "span.right",
                "Your subscription starts, unless you've cancelled during the trial."
              ),
            ]),
          ]),
          m(Testimonial, {
            image: "/images/testimonials/Heather-Simmonsen.jpg",
            quote:
              "I love how easy it is to create designs that adjust to different material thicknesses, kerf, and dimensions. Cuttle’s quickly become my favorite design tool.",
            name: "Heather Simmonsen (Chelsea Custom Creations)",
          }),
        ]),
      ]);
    },
  };
};

const TrialUpgradePlanSelector: m.Component<{ info: UpgradeInfo }> = {
  view({ attrs: { info } }) {
    return m(".claim-trial-upgrade-plan-selector", [
      m(".claim-trial-upgrade-plans", [
        m(
          ".claim-trial-upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "yearly" }),
            onclick: () => (info.paymentPeriod = "yearly"),
          },
          [
            m(TrialPlanCheckbox, { checked: info.paymentPeriod === "yearly" }),
            [
              m("h3", ["Yearly", m("span.best-value", "Best Value - Save US$48")]),
              m("span.left", "US$180 ", m("span.dim", "(US$15/month)")),
            ],
          ]
        ),
        m(
          ".claim-trial-upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "monthly" }),
            onclick: () => (info.paymentPeriod = "monthly"),
          },

          m(TrialPlanCheckbox, { checked: info.paymentPeriod === "monthly" }),
          [m("h3", "Monthly"), m("span.left", "US$19")]
        ),
      ]),
    ]);
  },
};

const TrialPlanCheckbox: m.Component<{ checked: boolean }> = {
  view({ attrs: { checked } }) {
    return m(".claim-trial-upgrade-plan-checkbox", [
      m(Icon20, { icon: checked ? "plan_checked" : "plan_unchecked" }),
    ]);
  },
};
